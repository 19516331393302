import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";

function loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Loading...</div>;
  } else {
    return <div className="animated fadeIn pt-3 text-center">Loading...</div>;
  }
}

// Containers
const PostLoginContainer = Loadable({
  loader: () => import("./PostLoginLayout"),
  loading,
});

// Pages
const Login = Loadable({
  loader: () => import("./PreLoginPages/Login"),
  loading,
});

const Page404 = Loadable({
  loader: () => import("./PreLoginPages/Page404"),
  loading,
});

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route path="/" name="Home" component={PostLoginContainer} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
